.spreadsheetsContainer {
  position: relative;
}
.spreadsheetToolbarWrapper {
  border-top: 1px solid #ababab;
  border-bottom: 1px solid #ababab;
  min-height: 39px;
  background-color: #ffffff;
  padding: 0 0.5rem;
  position: relative;
  white-space: nowrap;
}
.spreadsheetToolbar {
  min-height: 39px;
  margin-bottom: 0;
  outline: 0;
}
.spreadsheetToolbarSeparator {
  line-height: normal;
  outline: none;
  overflow: hidden;
  vertical-align: middle;
  margin: 0 5px;
  height: 39px;
  border-right: 1px solid #ababab;
}
.spreadsheetSideToolbar {
  background-color: #ffffff;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 1rem;
  top: 0;
  border-top: 1px solid #ababab;
}
.undoIcon {
  left: -3px;
  top: -3px;
}
.redoIcon {
  left: -28px;
  top: -3px;
}
.lockIcon {
  left: -51px;
  top: -3px;
}
.unlockIcon {
  left: -75px;
  top: -3px;
}
.ascendingIcon {
  left: -4px;
  top: -26px;
}
.descendingIcon {
  left: -28px;
  top: -26px;
}
.fillCellColorIcon {
  left: -4px;
  top: -76px;
}
.autofilterIcon {
  left: -51px;
  top: -26px;
}
.paintIcon {
  left: -75px;
  top: -27px;
}
.boldIcon {
  left: -3px;
  top: -50px;
}
.italicIcon {
  left: -27px;
  top: -51px;
}
.underlineIcon {
  left: -52px;
  top: -51px;
}
.textColorIcon {
  left: -75px;
  top: -52px;
}
.formatIcon {
  left: -18px;
  top: -57px;
}
.alignLeftIcon {
  left: -52px;
  top: -75px;
}
.alignCenterIcon {
  left: -75px;
  top: -74px;
}
.alignRightIcon {
  left: -75px;
  top: -98px;
}
.keyboardShortcutsIcon {
  left: 1px;
  top: -95px;
}
.configChageCodeIcon {
  left: 2px;
  top: -167px;
}
.reverseSignIcon {
  left: -52px;
  top: -96px;
}
.enterCompactIcon {
  left: 0px;
  top: -119px;
}
.exitCompactIcon {
  left: -23px;
  top: -117px;
}
.downloadFileIcon {
  left: -23px;
  top: -143px;
}
.saveFileIcon {
  left: -47px;
  top: -143px;
}
.referenceWindowIcon {
  left: -71px;
  top: -143px;
}
.calendarMonthsIcon {
  left: -28px;
  top: -171px;
}
.refreshIcon {
  content: url(../../../assets/images/refresh-icon.svg) !important;
  width: 18px;
  left: 4px;
  top: 4px;
}
.refreshIconOuterClass{
  border-color: #25c48c !important;
}

// formula bar
.formulaBarInputContainer {
  background-color: #fff;
  border-bottom: 1px solid #c0c0c0;
  height: 1.7rem;
  padding: 0;
  position: relative;
}
.formulaBarLabel {
  text-align: center;
  width: 120px;
  height: 1.6rem;
  line-height: 1.6rem;
  display: inline-block;
  padding: 1px 8px;
  font-weight: 600;
  background-color: #e5e8ec;
}
.formulaBar {
  background-image: url(../../../assets/images/fx.svg);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 0px center;
  padding-left: 36px;
  height: 1.6rem;
  line-height: 1.6rem;
  opacity: 0.7;
}
.pdfIcon {
  background-image: url(../../../assets/images/pdf.svg);
  background-repeat: no-repeat;
  background-size: 19px;
  background-position: 4px center;
  padding-left: 36px;
  height: 1.6rem;
  line-height: 1.6rem;
}
.excelIcon {
  background-image: url(../../../assets/images/excel-icon.svg);
  background-repeat: no-repeat;
  background-size: 19px;
  background-position: 4px center;
  padding-left: 36px;
  height: 1.6rem;
  line-height: 1.6rem;
}
.shareIcon {
  background-image: url(../../../assets/images/share-icon.png);
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 6px center;
  padding-left: 36px;
  height: 1.6rem;
  line-height: 1.6rem;
  opacity: 0.7;
}
.searchIcon {
  background-image: url(../../../assets/images/search-icon.svg);
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: 0px 1px;
  padding-left: 36px;
  height: 1.6rem;
  line-height: 1.6rem;
  opacity: 0.7;
}
.formulaBarInput {
  border: none;
  position: relative;
}
.cellInput {
  word-wrap: break-word;
  width: 100%;
  height: 100%;
  margin: 0;
  outline: none;
  cursor: text;
  border: none;
  white-space: pre-wrap;
  background-color: white;
  color: rgba(0, 0, 0, 0.7);
}

// menu dropdwon
.spreadsheetToolbarDropdownButton {
  button {
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.7) !important;
    background-color: #ffffff;
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    border-radius: 3px;
    &:hover,
    &:focus,
    &:active {
      background-color: #f1f3f5 !important;
      border-color: #ababab !important;
      border: none;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

// search box stylelint
.searchBox {
  position: absolute;
  right: 25px;
  background-color: #fff;
  border-bottom: 1px solid #c0c0c0;
  border-top: none;
  border-left: 1px solid #c0c0c0;
  border-right: 1px solid #c0c0c0;
  margin-top: -1px;
  outline: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding: 16px 12px;
  width: 317px;
  top: 68px;
  input {
    border: 1px solid #dadce0;
    border-radius: 4px;
    box-sizing: border-box;
    color: #3c4043;
    padding: 1px 8px;
    font-size: 14px;
    height: 36px;
    margin: 8px 0;
    margin: 0;
    width: 73%;
  }
}