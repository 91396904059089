@use "sass:meta" as ---f6f0r07g7t4;// Variables

// Color system
$white:      #ffffff !default;
$gray-100:   #FBFBFB !default;
$gray-200:   #e9ecef !default;
$gray-300:   #dee2e6 !default;
$gray-400:   #ced4da !default;
$gray-500:   #B0B0B0 !default;
$gray-600:   #6c757d !default;
$gray-700:   #495057 !default;
$gray-800:   #1d456d !default;
$gray-900:   #222222 !default;
$black-300:  #3a3a3a !default;
$black:      #000000 !default;
$light-blue: #f1f5ff !default;
$light-gray: #f5f7f9 !default;
$blue-hover: #0069d9 !default;
$light-border: #ababab  !default;
$red-600: #c8063c  !default;

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue:    #145f97 !default;
$darkBlue: #0f426a !default;
$indigo:  #3e58da !default;
$purple:  #6f42c1 !default;
$pink:    #FF385C !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffbd05 !default;
$green:   #28a745 !default;
$teal:    #cce8ff !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

// Spacing
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
  ),
  $spacers
);

// Body
$body-bg:                     $white !default;
$body-color:                  $gray-900 !default;

// Links
$link-color:                  $blue !default;
$link-decoration:             none !default;
$link-hover-color:            $darkBlue !default;
$link-hover-decoration:       underline !default;

// Components
$line-height:                 1.5 !default;
$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;
$border-width:                1px !default;
$border-color:                $gray-400 !default;
$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-xlg:           .5rem !default;
$border-radius-sm:            .2rem !default;
$border-radius-album:         1rem !default;
$border-radius-md:            .8rem !default;
$rounded-pill:                50rem !default;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;
$box-shadow-album:            rgba($black, 0.15) 0px 2px 8px !default;
$box-shadow-card:             rgba($black, 0.15) 0px 0px 8px !default;
$box-shadow-blue:             0 0 5px rgba($primary, 0.88)!default;

$component-active-color:      $white !default;
$component-active-bg:         theme-color("primary") !default;

// stylelint
$font-size-base:              1rem !default;
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;
$font-size-small:             11px !default;
$font-size-msmall:            12px !default;
$font-size-xsmall:            13px !default;
$font-size-default:           14px !default;
$font-size-lsmall:            15px !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-smedium:         500 !default;
$font-weight-medium:          600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;
$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

$headings-margin-bottom:      $spacer / 2 !default;
$headings-font-family:        null !default;
$headings-font-weight:        400 !default;
$headings-line-height:        1.2 !default;
$headings-color:              null !default;

$small-font-size:             80% !default;
$text-muted:                  $gray-600 !default;
$text-error:                  $red-600 !default;

// Tables
$table-cell-padding:          .5rem !default;
$table-cell-padding-sm:       .3rem !default;
$table-color:                 $body-color !default;
$table-bg:                    null !default;
$table-accent-bg:             rgba($black, .05) !default;
$table-hover-color:           $table-color !default;
$table-hover-bg:              rgba($light-blue, 0.5) !default;
$table-active-bg:             $table-hover-bg !default;
$table-border-width:          $border-width !default;
$table-border-color:          $border-color !default;
$table-head-bg:               $light-gray !default;
$table-head-color:            $light-blue !default;
$table-dark-color:            $white !default;
$table-dark-bg:               $gray-800 !default;
$table-dark-accent-bg:        rgba($white, .05) !default;
$table-dark-hover-color:      $table-dark-color !default;
$table-dark-hover-bg:         rgba($white, .075) !default;
$table-dark-border-color:     lighten($table-dark-bg, 7.5%) !default;
$table-striped-order:         odd !default;
$table-caption-color:         $text-muted !default;
$table-bg-level:              -9 !default;
$table-border-level:          -6 !default;

// Buttons + Forms
$input-btn-padding-y:         .3rem !default;
$input-btn-padding-x:         1rem !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-default !default;
$input-btn-line-height:       $line-height-base !default;
$input-btn-focus-width:       .2rem !default;
$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      $font-size-sm !default;
$input-btn-line-height-sm:    $line-height-sm !default;
$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;
$input-btn-line-height-lg:    $line-height-lg !default;
$input-btn-border-width:      $border-width !default;

// Buttons
$btn-padding-y:               .4rem !default;
$btn-padding-x:               .75rem !default;
$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;
$btn-white-space:             null !default;
$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;
$btn-line-height-sm:          $input-btn-line-height-sm !default;
$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;
$btn-line-height-lg:          $input-btn-line-height-lg !default;
$btn-border-width:            $input-btn-border-width !default;
$btn-font-weight:             $font-weight-normal !default;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;
$btn-link-disabled-color:     $gray-600 !default;
$btn-block-spacing-y:         .5rem !default;

// Button border radius
$btn-border-radius:           $border-radius !default;
$btn-border-radius-lg:        $border-radius-lg !default;
$btn-border-radius-xlg:       $border-radius-xlg !default;
$btn-border-radius-sm:        $border-radius-sm !default;
$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// Forms
$label-margin-bottom:               .5rem !default;
$input-padding-y:                   $input-btn-padding-y !default;
$input-padding-x:                   $input-btn-padding-x !default;
$input-font-family:                 $input-btn-font-family !default;
$input-font-size:                   $input-btn-font-size !default;
$input-color:                       $gray-700 !default;
$input-font-weight:                 $font-weight-base !default;
$input-line-height:                 $input-btn-line-height !default;
$input-padding-y-sm:                $input-btn-padding-y-sm !default;
$input-padding-x-sm:                $input-btn-padding-x-sm !default;
$input-font-size-sm:                $input-btn-font-size-sm !default;
$input-line-height-sm:              $input-btn-line-height-sm !default;
$input-padding-y-lg:                $input-btn-padding-y-lg !default;
$input-padding-x-lg:                $input-btn-padding-x-lg !default;
$input-font-size-lg:                $input-btn-font-size-lg !default;
$input-line-height-lg:              $input-btn-line-height-lg !default;
$input-bg:                          $white !default;
$input-disabled-bg:                 $gray-200 !default;
$input-color:                       $gray-700 !default;
$input-border-color:                $gray-500 !default;
$input-border-width:                $input-btn-border-width !default;
$input-box-shadow:                  inset 0 1px 1px rgba($black, .075) !default;
$input-border-radius:               $border-radius !default;
$input-border-radius-lg:            $border-radius-lg !default;
$input-border-radius-sm:            $border-radius-sm !default;
$input-focus-bg:                    $input-bg !default;
$input-focus-color:                 $input-color !default;
$input-focus-width:                 $input-btn-focus-width !default;
$input-placeholder-color:           $input-color !default;
$input-plaintext-color:             $body-color !default;
$input-height-border:               $input-border-width * 2 !default;
$input-transition:                  border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// Z-index master list
$zindex-dropdown:                   1000 !default;
$zindex-sticky:                     1020 !default;
$zindex-fixed:                      1030 !default;
$zindex-modal-backdrop:             1040 !default;
$zindex-modal:                      1050 !default;
$zindex-popover:                    1060 !default;
$zindex-tooltip:                    1070 !default;

// Navs
$nav-link-padding-y:                .5rem !default;
$nav-link-padding-x:                1rem !default;
$nav-link-disabled-color:           $gray-600 !default;
$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $gray-700 !default;
$nav-tabs-link-active-bg:           $body-bg !default;
$nav-tabs-link-active-border-color: $nav-tabs-link-active-bg $nav-tabs-link-active-bg $gray-900 $nav-tabs-link-active-bg !default;
$nav-pills-border-radius:           $border-radius !default;
$nav-pills-link-active-color:       $component-active-color !default;
$nav-pills-link-active-bg:          $component-active-bg !default;
$nav-divider-color:                 $gray-200 !default;
$nav-divider-margin-y:              $spacer / 2 !default;

// Navbar
$navbar-padding-y:                  $spacer / 1 !default;
$navbar-padding-x:                  $spacer !default;
$navbar-nav-link-padding-x:         .8rem !default;
$navbar-brand-font-size:            $font-size-lg !default;
$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2 !default;
$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
$navbar-toggler-border-radius:      $btn-border-radius !default;
$navbar-dark-color:                 rgba($white, .5) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;
$navbar-light-color:                rgba($black, .8) !default;
$navbar-light-hover-color:          rgba($black, .7) !default;
$navbar-light-active-color:         rgba($black, .9) !default;
$navbar-light-disabled-color:       rgba($black, .3) !default;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;
$navbar-light-brand-color:          $navbar-light-active-color !default;
$navbar-light-brand-hover-color:    $navbar-light-active-color !default;
$navbar-dark-brand-color:           $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color:     $navbar-dark-active-color !default;

// Dropdowns
$dropdown-min-width:                12rem !default;
$dropdown-padding-y:                .5rem !default;
$dropdown-spacer:                   .125rem !default;
$dropdown-font-size:                14px !default;
$dropdown-color:                    $body-color !default;
$dropdown-bg:                       $white !default;
$dropdown-border-color:             rgba($black, .15) !default;
$dropdown-border-radius:            $border-radius !default;
$dropdown-border-width:             $border-width !default;
$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width) !default;
$dropdown-divider-bg:               $gray-200 !default;
$dropdown-divider-margin-y:         $nav-divider-margin-y !default;
$dropdown-box-shadow:               rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px !default;
$dropdown-link-color:               $gray-900 !default;
$dropdown-link-hover-color:         darken($gray-900, 5%) !default;
$dropdown-link-hover-bg:            $gray-100 !default;
$dropdown-link-active-color:        $component-active-color !default;
$dropdown-link-active-bg:           $component-active-bg !default;
$dropdown-link-disabled-color:      $gray-600 !default;
$dropdown-item-padding-y:           .6rem !default;
$dropdown-item-padding-x:           .7rem !default;
$dropdown-header-color:             $gray-600 !default;

// Pagination
$pagination-padding-y:              .5rem !default;
$pagination-padding-x:              .75rem !default;
$pagination-padding-y-sm:           .25rem !default;
$pagination-padding-x-sm:           .5rem !default;
$pagination-padding-y-lg:           .75rem !default;
$pagination-padding-x-lg:           1.5rem !default;
$pagination-line-height:            1.25 !default;
$pagination-color:                  $gray-900 !default;
$pagination-bg:                     $white !default;
$pagination-border-width:           $border-width !default;
$pagination-border-color:           $gray-300 !default;
$pagination-focus-outline:          0 !default;
$pagination-hover-color:            $link-hover-color !default;
$pagination-hover-bg:               $gray-200 !default;
$pagination-hover-border-color:     $gray-300 !default;
$pagination-active-color:           $component-active-color !default;
$pagination-active-bg:              $component-active-bg !default;
$pagination-active-border-color:    $pagination-active-bg !default;
$pagination-disabled-color:         $white !default;
$pagination-disabled-bg:            $gray-900 !default;
$pagination-disabled-border-color:  $gray-300 !default;

// Cards
$card-spacer-y:                     .75rem !default;
$card-spacer-x:                     1.25rem !default;
$card-body-spacer-x:                1.5rem !default;
$card-border-width:                 $border-width !default;
$card-border-radius:                $border-radius !default;
$card-border-color:                 rgba($black, .125) !default;
$card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
$card-cap-bg:                       rgba($black, .03) !default;
$card-cap-color:                    null !default;
$card-height:                       null !default;
$card-color:                        null !default;
$card-bg:                           $white !default;
$card-album-border-radius:          subtract($border-radius-album, $border-radius-album) !default;
$card-listing-border-radius:        subtract($border-radius-album, $border-radius-album) !default;
$card-img-overlay-padding:          1.25rem !default;
$card-columns-count:                3 !default;
$card-columns-gap:                  1.25rem !default;
$card-columns-margin:               $card-spacer-y !default;

// Badges
$badge-font-size:                   10px !default;
$badge-font-weight:                 $font-weight-bold !default;
$badge-padding-y:                   .35em !default;
$badge-padding-x:                   .7em !default;
$badge-border-radius:               $border-radius !default;
$badge-transition:                  $btn-transition !default;
$badge-focus-width:                 .2rem !default;
$badge-pill-padding-y:              .4em !default;
$badge-pill-padding-x:              .6em !default;
$badge-pill-border-radius:          $rounded-pill !default;

// Modals
$modal-inner-padding:               1rem !default;
$modal-footer-margin-between:       .5rem !default;
$modal-dialog-margin:               .5rem !default;
$modal-dialog-margin-y-sm-up:       1.75rem !default;
$modal-title:                       $font-size-base * 1.15 !default;
$modal-title-line-height:           $line-height-base !default;
$modal-content-color:               null !default;
$modal-content-bg:                  $white !default;
$modal-content-border-color:        rgba($black, .2) !default;
$modal-content-border-width:        $border-width !default;
$modal-content-border-radius:       $border-radius-lg !default;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5) !default;
$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5) !default;
$modal-backdrop-bg:                 rgba($black, .7)!default;
$modal-backdrop-opacity:            .5 !default;
$modal-header-bg:                   $black-300 !default;
$modal-header-border-color:         $gray-500 !default;
$modal-footer-border-color:         $modal-header-border-color !default;
$modal-header-border-width:         $modal-content-border-width !default;
$modal-footer-border-width:         $modal-header-border-width !default;
$modal-header-padding-y:            .8rem !default;
$modal-header-padding-x:            1rem !default;
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default;
$modal-xlg:                         100% !default;
$modal-xl:                          1140px !default;
$modal-lg:                          800px !default;
$modal-md:                          500px !default;
$modal-sm:                          300px !default;
$modal-fade-transform:              translate(0, -50px) !default;
$modal-show-transform:              none !default;
$modal-transition:                  transform .3s ease-out !default;
$modal-scale-transform:             scale(1.02) !default;

// Alerts
$alert-padding-y:                   .75rem !default;
$alert-padding-x:                   1.25rem !default;
$alert-margin-bottom:               1rem !default;
$alert-border-radius:               $border-radius !default;
$alert-link-font-weight:            $font-weight-bold !default;
$alert-border-width:                $border-width !default;
$alert-bg-level:                    -10 !default;
$alert-border-level:                -9 !default;
$alert-color-level:                 6 !default;

// List group
$list-group-color:                  null !default;
$list-group-bg:                     $white !default;
$list-group-border-color:           rgba($black, .125) !default;
$list-group-border-width:           $border-width !default;
$list-group-border-radius:          $border-radius !default;
$list-group-item-padding-y:         .75rem !default;
$list-group-item-padding-x:         1.25rem !default;
$list-group-hover-bg:               $light-blue !default;
$list-group-active-color:           $component-active-color !default;
$list-group-active-bg:              $component-active-bg !default;
$list-group-active-border-color:    $list-group-active-bg !default;
$list-group-disabled-color:         $gray-600 !default;
$list-group-disabled-bg:            $list-group-bg !default;
$list-group-action-color:           $gray-700 !default;
$list-group-action-hover-color:     $list-group-action-color !default;
$list-group-action-active-color:    $body-color !default;
$list-group-action-active-bg:       $gray-200 !default;

// Breadcrumbs
$breadcrumb-font-size:              null !default;
$breadcrumb-padding-y:              .75rem !default;
$breadcrumb-padding-x:              1rem !default;
$breadcrumb-item-padding:           .5rem !default;
$breadcrumb-margin-bottom:          1rem !default;
$breadcrumb-bg:                     $gray-200 !default;
$breadcrumb-divider-color:          $gray-600 !default;
$breadcrumb-active-color:           $gray-600 !default;
$breadcrumb-divider:                quote("/") !default;
$breadcrumb-border-radius:          $border-radius !default;

// Document Tagging
$document-tag-padding-y:            .4rem !default;
$document-tag-padding-x:            .3rem !default;
$document-tag-header-padding:        $document-tag-padding-y $document-tag-padding-x !default;

// Spinners
$spinner-width:                     2rem !default;
$spinner-height:                    $spinner-width !default;
$spinner-border-width:              .25em !default;
$spinner-width-sm:                  1rem !default;
$spinner-height-sm:                 $spinner-width-sm !default;
$spinner-border-width-sm:           .2em !default;

// Close
$close-font-size:                   $font-size-base * 1.5 !default;
$close-font-weight:                 $font-weight-bold !default;
$close-color:                       $white !default;
$close-text-shadow:                 0 1px 0 $black !default;

;@include ---f6f0r07g7t4.load-css("sass-embedded-legacy-load-done:63");